<template>
    <div class="material zhong">
        <!--    头部导航    ?-->
        <hd-com></hd-com>
        <!--    banner    -->
        <div class="banner">
            <div class="banner-text">
                <h3>中药库</h3>
                <p>ZHONG YAO</p>
            </div>
        </div>
        <!--    内容    -->
        <div class="content">
            <div class="content-box clearfix">
                <div class="content-left">
                    <!-- <div class="hot-search">
                        <p>热门搜索</p>
                        <ul class="clearfix">
                            <li v-for="hotsearch in hots.slice(0, 6)" :key="hotsearch.index"
                                @click="toMaterialDetail(hotsearch.hashKey)">
                                <span>{{ hotsearch.materialName }}</span>
                            </li>
                        </ul>
                    </div> -->
                    <div class="sidenav">
                        <h3 @click="searchMaterial(0)">全部中药</h3>
                        <el-menu class="el-menu-vertical-demo" :unique-opened="true">
                            <template>
                                <el-submenu  v-for="(sideNav,index) in materialNavList" :key="index" :index="index + ''">
                                    <template slot="title">
                                        <span>{{ sideNav.categoryName }}</span>
                                    </template>
                                    <div class="subtitle">
                                        <el-menu-item @click="searchMaterial(sideNav.categoryId)" class=""  :index="index + ''">
                                            <template slot="title">
                                                全部
                                            </template>
                                        </el-menu-item>
                                        <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i"
                                            :index="subItem.categoryId + ''" @click="searchMaterial(subItem.categoryId)">
                                            <template slot="title">
                                                {{ subItem.categoryName }}
                                            </template>
                                        </el-menu-item>
                                    </div>
                                </el-submenu>
                            </template>
                        </el-menu>
                    </div>
                </div>
                <div class="content-right">
                    <div class="result-num">
                        <p>为您找到 <span>{{ total }}</span><em>个结果</em></p>
                    </div>
                    <ul class="result-list clearfix">
                        <li class="result-item" v-for="(materialDetailItem, i) in materialDetailList" :key="i">
                            <div>
                                <div class="result-img">
                                    <div v-if="!materialDetailItem.materialPic" class="img-box"
                                        @click="toMaterialDetail(materialDetailItem.hashKey)">
                                        <img src="../../assets/img/zyc_img.png" alt="">
                                    </div>
                                    <div v-else class="img-box" style="position: relative">

                                        <el-image :src="materialDetailItem.materialPic"
                                            :preview-src-list="materialDetailItem.pics" alt="" lazy
                                            style="position: absolute;opacity: 0;"></el-image>
                                        <img v-lazy="materialDetailItem.materialPic" alt="">
                                    </div>
                                </div>
                                <div class="result-tit clearfix"
                                    @click="toMaterialDetail(materialDetailItem.hashKey)">
                                    <div class="result-name">
                                        <h3><i>{{ materialDetailItem.materialName }}</i>
                                        <span style="margin-top: 5px;">{{ category(materialDetailItem) }}</span>
                                        </h3>
                                        <div class="oneLines" style="width: 100%;line-height: 1;">别名：{{ materialDetailItem.materialAlias?materialDetailItem.materialAlias:'无'}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="result-hover">
                                <div class="add-pw left" @click="onCompatibility(materialDetailItem)">+加入配伍</div>
                                <div class="collect-btn right">
                                    <i class="el-icon-star-off" @click="onCollection(materialDetailItem)"></i>
                                </div>
                            </div> -->
                        </li>
                    </ul>
                    <div id="page">
                        <el-pagination background layout="prev, pager, next,total" :page-size="15"
                            @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total">
                        </el-pagination>
                    </div>


                </div>
            </div>
        </div>
        <!--    footer    -->
        <div id="footer">
            <div class="footer-con">
                <!-- <div class="footer-top clearfix">
                    <div class="footer-logo">
                        <img src="../../assets/img/logo-footer.png" alt="">
                    </div>
                    <div class="footer-nav">
                        <router-link to="/index">首页</router-link>
                        <span>-</span>
                        <router-link to="/company/company">公司介绍</router-link>
                        <span>-</span>
                        <router-link to="/company/contactUs">联系我们</router-link>
                        <span>-</span>
                        <a target="_blank" href="/20210712091219.pdf">使用帮助</a>
                        <span>-</span>
                        <router-link to="/company/disclaimer">隐私政策</router-link>
                        <span>-</span>
                        <router-link to="/company/UserAgreement">服务协议</router-link>
                    </div>
                    <div class="qrcode clearfix">
                        <div class="left">
                            <img src="../../assets/img/qrcode.png" alt="">
                            <p>微信公众号</p>
                        </div>
                        <div class="right">
                            <img src="../../assets/img/wechat.jpg" alt="">
                            <p>转诊小程序</p>
                        </div>
                    </div>
                </div> -->
                <div class="copyright">
                    <p>©2021qyyl365.com版权所有<a style="color: #ffffff;" href="http://www.beian.gov.cn/portal/index.do"
                            target="view_window">豫ICP备2021000748号</a>.<span>千越医疗科技有限公司提供技术支持</span></p>
                </div>
            </div>
        </div>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
import rtBar from "../../components/right";
import { Material } from '../../components/Drug/Material'
import { search } from '../../utils/relation'
import storage from '../../utils/customstorage'
import { loginOut } from '../../utils/userInfo'
import { Collection } from "../../components/collection/Collection"
import { addCompare, getCompatibilityList } from '../../utils/Compatibility'
var searchId = 0
var pageIndex = 1
var categoryId = 0
var keyword = ''
export default {
    name: "Material",
    components: {
        hdCom,
        ftCom,
        rtBar
    },
    inject: ["reload"],
    data() {
        var material = new Material(this.TokenClient, this.Services.Drug)
        var collection = new Collection(this.TokenClient, this.Services.Statics)
        return {
            searchFrom: {
                selectList: [
                    {
                        id: 'all',
                        name: '全站'
                    },
                    {
                        id: 'disease',
                        name: '疾病'
                    },
                    {
                        id: 'zhengzhuang',
                        name: '症状'
                    },
                    {
                        id: 'drug',
                        name: '药品'
                    },
                    {
                        id: 'material',
                        name: '中药'
                    },
                    {
                        id: 'food',
                        name: '食疗药膳'
                    },
                    {
                        id: 'herbal',
                        name: '中药方剂'
                    },
                    {
                        id: 'meridian',
                        name: '经络穴位'
                    },
                ],
                headSelected: 'all',
                keywords: ''
            },
            checkedhot: [],
            hots: [],
            materialNavList: [],
            materialDetailList: [],
            total: 0,
            currentPage: 1, //初始页
            rloading: [], //loading遮罩
            userId: this.$store.state.localData.userInfo.id,
            userName: this.$store.state.localData.userInfo.name,
            userhead: this.$store.state.localData.userInfo.photo,
            materialDomain: material,
            collectionDomain: collection
        }
    },
    created() {
        if (this.$route.query.searchType != null) this.searchFrom.headSelected = this.$route.query.searchType;
        if (this.$route.query.keyword != null) this.searchFrom.keywords = this.$route.query.keyword;
    },
    mounted() {
        this.$bus.$emit('isShowChangeSize');
        this.$bus.$emit('select', 'material')
        this.getmaterialNavListData()
        this.materialHotspot()
        // categoryId = this.$route.query.categoryId
        keyword = this.$route.query.keyword
        this.searchMaterial(categoryId,pageIndex)
    },
    methods: {
        submitSearch: function () {
            search(this.searchFrom.headSelected, this.searchFrom.keywords)
        },
        SearchInputKeydown(event) {
            search(this.searchFrom.headSelected, this.searchFrom.keywords)
        },
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        },
        //左侧分类获取
        getmaterialNavListData: function () {
            var _this = this;
            _this.materialDomain.mzCategorys(function (data) {
                _this.materialNavList = (data.data)
            },
                function (error) {
                    console.log("中药分类请求失败:", error)
                })
        },
        //热门搜索词获取
        materialHotspot: function () {
            var _this = this;
            _this.materialDomain.Hotspot(function (data) {
                _this.hots = data.data
            },
                function (error) {
                    console.log("热门搜索词请求失败:", error)
                })
        },
        searchMaterial(cateId, isPage = false) {
            categoryId = cateId;
            var _this = this;
            _this.rloading = _this.openLoading()
            if (!isPage) pageIndex = 1
            if (cateId == undefined) cateId = 0
            let params = `/${cateId}/${pageIndex}`
            if (keyword != '' && keyword != undefined) {
                params = params + `/1/${keyword}`
            } else {
                params += '/1/%20';
            }
            _this.materialDomain.mzMaterials(params, function (data) {
                _this.materialDetailList = data.data.results
                _this.total = data.data.dataTotal
                searchId = data.msg
                _this.rloading.close()
            },
                function (error) {
                    console.log("中药材请求失败:", error)
                })
            if (!isPage) {
                this.currentPage = 1
            }
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //右侧中药属性赋值
        category: function (data) {
            var categoryName = "";
            if (data.category != null) {
                if (data.category.Parent != null) {
                    categoryName = data.category.Parent.categoryName + '/'
                }
                categoryName += data.category.categoryName;
            }
            return categoryName
        },
        //分页点击事件
        handleCurrentChange: function (pageNum) {
            this.currentPage = pageNum
            pageIndex = pageNum
            this.searchMaterial(categoryId, true);
            // chrome
            document.body.scrollTop = 0
            // firefox
            document.documentElement.scrollTop = 0
            // safari
            window.pageYOffset = 0
        },
        //跳转到详情
        toMaterialDetail(hashKey) {
            ///api/Material/{hashKey}
            this.$router.push({
                path: '/Drug/MaterialDetail',
                //name:'materialDetail',
                query: {
                    hashKey: hashKey,
                }
            })
        },
        onCollection: function (item) {
            var _this = this;
            _this.collectionDomain.Put("material", item.materialName, item.hashKey, item.materialId, 0,
                function (data) {
                    _this.$message({
                        type: 'success',
                        message: '收藏成功!'
                    });
                }, function (err) {
                    if (err.status === 400) {
                        _this.$message.error('请勿重复收藏');
                    }
                });
        },
        out() {
            loginOut()
            this.$message.success("操作成功")
            this.reload()
        }
    },
    watch: {
        $route() {
            keyword = this.$route.query.keyword
            this.searchMaterial(categoryId,pageIndex);
        }
    }
}
</script>

<style scoped>
.zyheader {
    height: 80px;
    background: #ffffff;
}

.zyheader .head-con {
    width: 1440px;
    margin: 0 auto;
}

.zyheader .logo {
    line-height: 80px;
    margin-right: 20px;
}

.zyheader .logo img {
    margin-top: 15px;
}

.zyheader .navbar ul li {
    float: left;
    padding: 20px 25px;
    line-height: 40px;
    position: relative;
}

.zyheader .navbar ul li a {
    display: inline-block;
    position: relative;
    border-width: 0;
    font-size: 16px;
    line-height: 40px;
}

.zyheader .navbar ul li:hover>a {
    display: inline-block;
    color: #b76c12;
}

.zyheader .navbar ul li>a::before {
    content: "";
    position: absolute;
    /* 重点 */
    left: 0;
    /* 重点 */
    bottom: 0;
    /* 重点 */
    width: 0;
    /* 重点 */
    height: 2px;
    background: #b76c12;
    transition: width .5s;
}

.zyheader .navbar ul li:hover>a::before {
    width: 100%;
}

.zyheader .navbar ul li.active>a::before {
    width: 100%;
}

.zyheader .navbar ul li.active>a {
    color: #b76c12;
}

.zyheader .navbar ul li:hover .sub-nav {
    display: block;
}

.zyheader .sub-nav {
    display: none;
    position: absolute;
    top: 62px;
    padding: 10px 0;
    left: -40%;
    width: 150px;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 2px 0px 2px #f0f0f0;
    z-index: 9;
}

.zyheader .sub-nav dt a {
    display: block !important;
    text-align: center;
    font-size: 14px !important;
    color: #333;
}

.zyheader .sub-nav dt:hover a {
    color: #b76c12 !important;
}

.zyheader .personalCenter {
    margin: 0 100px 0 70px;
    position: relative;
    float: right;
    font-size: 14px;
    color: #333;
}

.zyheader .personalCenter>p {
    text-align: center;
    line-height: 80px;
}

.zyheader .personalCenter .login-txt {
    text-align: center;
    line-height: 80px;
    cursor: pointer;
}

.zyheader .personalCenter:hover .per-hover {
    display: block;
}

.zyheader .per-hover {
    display: none;
    position: absolute;
    left: -125px;
    width: 300px;
    height: 250px;
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 3px 0px 5px #D9D9D9;
    z-index: 99;
}

.zyheader .head-box {
    padding-top: 15px;
    background: #f9f9f9;
}

.zyheader .head-img {
    margin: 0 auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.zyheader .head-img img {
    width: 100%;
    height: 100%;
}

.zyheader .head-box>p {
    line-height: 45px;
    text-align: center;
}

.zyheader .per-info li {
    margin: 5px 10px;
    float: left;
    width: 76px;
    height: 68px;
}

.zyheader .per-info li p {
    line-height: 1;
    text-align: center;
}

.zyheader .per-info li:hover {
    background: #b76c12;
}

.zyheader .per-info li:hover p {
    color: #ffffff;
}

.zyheader .s-icon {
    width: 25px;
    height: 25px;
    margin: 10px auto;
}

.zyheader .s-icon1 {
    background: url("../../assets/img/s_icon1.jpg") center no-repeat;
}

.zyheader .s-icon2 {
    background: url("../../assets/img/s_icon2.jpg") center no-repeat;
}

.zyheader .s-icon3 {
    background: url("../../assets/img/s_icon3.png") center no-repeat;
}

.zyheader .per-info li:hover .s-icon1 {
    background: url("../../assets/img/w_icon1.png") center no-repeat;
}

.zyheader .per-info li:hover .s-icon2 {
    background: url("../../assets/img/w_icon2.png") center no-repeat;
}

.zyheader .per-info li:hover .s-icon3 {
    background: url("../../assets/img/w_icon3.png") center no-repeat;
}

.zyheader .status {
    border-top: 1px solid #ebebeb;
}

.zyheader .status span {
    display: inline-block;
    font-size: 14px;
    color: #333;
    width: 49%;
    text-align: center;
    line-height: 60px;
}

.search {
    margin-top: 20px;
    padding-left: 10px;
    height: 40px;
    line-height: 38px;
    border: 1px solid #b76c12;
    border-radius: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.search input {
    width: 200px;
    padding: 0 5px;
}

.search .search-btn {
    width: 55px;
    height: 38px;
    background: #b76c12 url("../../assets/img/search.png")center no-repeat;
    vertical-align: top;
}

/*banner*/
.banner {
    height: 116px;
    background: #b76c12;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
    text-transform: uppercase;
}

.banner-text p {
    font-size: 18px;
    color: #ffffff;
}

/*content*/
.content {
    background: #fcfcfc;
}

.content-box {
    width: 1300px;
    margin: 0 auto;
}

.content-left {
    float: left;
    width: 320px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.06);
}

.hot-search ul li {
    float: left;
    width: 33%;
    margin-top: 10px;
}

.hot-search ul li span {
    font-size: 14px;
    color: #666666;
    cursor: pointer;
}

.hot-search ul li span:hover {
    color: #b76c12;
}

.zhongxi {
    margin-bottom: 15px;
}

.zhongxi li {
    float: left;
    margin-right: 10px;
    padding: 3px 0;
    width: 100px;
    border: 1px solid #cccccc;
    text-align: center;
}

.sidenav {
    margin-top: 30px;
}

.sidenav>h3 {
    width: 310px;
    padding-left: 10px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    color: #ffffff;
    background: #b76c12;
    font-weight: bold;
}

/*content-right*/
.content-right {
    float: right;
    width: 900px;
    margin-left: 40px;
}

.result-num {
    padding: 35px 0 25px;
    font-size: 28px;
    font-weight: bold;
    color: #222222;
}

.result-num span,
.result-num em {
    color: #b76c12;
    font-style: normal;
}

.result-item {
    position: relative;
    margin: 0 20px 20px 0;
    float: left;
    width: 278px;
    height: 250px;
    border-radius: 10px;
    border: 1px solid #f0e9e2;
    background: #faf7f3;
    overflow: hidden;
    cursor: pointer;
}
.result-item:hover{
    box-shadow: 3px 2px 8px rgb(0 0 0 / 20%);
}
.result-item:nth-child(3n){
    margin-right: 0;
}
.result-item .result-img {
    width: 100%;
    height: 190px;
    overflow: hidden;
}

.result-item .result-img .img-box {
    display: block;
    text-align: center;
    line-height: 190px;
    height: 190px;
    overflow: hidden;
}

.result-item .result-img .img-box img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.result-item .result-tit {
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    padding: 0 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.material .result-name h3 {
    line-height: 2;
}
.material .result-name{
    width: 100%;
}

.result-name h3 i {
    display: inline-block;
    color: #333333;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    vertical-align: top;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.result-name h3 span {
    display: inline-block;
    margin-left: 12px;
    font-size: 14px;
    font-weight: normal;
    color: #666666;
    vertical-align: top;
    max-width: 114px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.result-hover {
    position: absolute;
    width: 270px;
    height: 40px;
    background: rgba(194, 134, 62, 0.6);
    top: -40px;
    transition: all linear .2s;
    cursor: pointer;
}

.result-hover .add-pw {
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: left;
    color: #FFFFFF;
}

.result-hover .add-pw:hover {
    color: #b76c12;
}

.collect-btn i {
    padding-right: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
    color: #FFFFFF;
}

.collect-btn i:hover {
    color: #b76c12;
}

.result-item:hover .result-hover {
    top: 0px;
    transition: all linear .2s;
}

.result-item .result-tit:hover .result-name h3 i {
    color: #b76c12;
}

.result-item .result-tit:hover .result-name h3 span {
    color: #d58f3a;
}

#page {
    text-align: center;
    padding: 100px 0 30px;
}

/*footer*/
.material #footer {
    background: #333333;
}

.material #footer .footer-con {
    width: 1200px;
    margin: 0 auto;
}

.material #footer .footer-top {
    padding: 40px 0;
}

.material #footer .footer-logo {
    float: left;
    line-height: 80px;
}

.material #footer .footer-logo img {
    margin-top: 15px;
}

.material #footer .footer-nav {
    float: left;
    margin-left: 150px;
    line-height: 80px;
}

.material #footer .footer-nav span {
    margin: 0 10px;
}

.material #footer .footer-nav a,
.footer-nav span {
    color: #ffffff;
}

.material #footer .footer-nav a:hover {
    color: #b76c12;
}

.material #footer .qrcode {
    float: right;
}

.material #footer .qrcode img {
    width: 110px;
}

.material #footer .qrcode>div {
    margin-left: 15px;
}

.material #footer .qrcode div p {
    text-align: center;
    color: #ffffff;
}

.material #footer .copyright {
    padding: 30px;
    border-top: 1px solid rgba(183, 108, 18, 0.2);
}

.material #footer .copyright p {
    color: #ffffff;
    text-align: center;
}

.material #footer .copyright p span {
    color: #b76c12;
}
/* **************** 左侧导航样式*/
.sidenav /deep/ .el-submenu__title>span {
    font-size: 16px;
    font-weight: 700;
}
.sidenav /deep/ .el-submenu__title i {
    color: #ffffff;
}
.zhong  /deep/ .el-submenu__title {
  padding: 0 !important;
  background: #b76c12;
  color: #ffffff;
  margin-top: 7px;
}
.zhong .el-menu-item.is-active,
.zhong .el-menu-item:hover {
  color: #b76c12;
  background: rgba(183, 108, 18, 0.2);
}
.material .bar-list .yysc {
    display: none !important;
}

.material .el-badge__content.is-dot {
    height: 6px;
    width: 6px;
}

.material .el-badge__content.is-fixed.is-dot {
    right: 0px;
    top: 11px;
}

.material .el-select {
    width: 95px;
}

.material .el-input__inner {
    border: none;
    padding: 0 5px;
}

.material .el-checkbox {
    width: 33.3%;
    margin: 10px 0;
}

.material .el-checkbox__input.is-checked .el-checkbox__inner,
.material .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #b76c12;
    border-color: #b76c12;
}

.material .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #b76c12;
}

.material .el-checkbox__inner:hover {
    border-color: #b76c12;
}

.material .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #b76c12;
}

.material .el-menu {
    border-right: none;
}

.material .el-submenu__title {
    padding: 0 !important;
    background: #b76c12;
    color: #FFFFFF;
    margin-top: 7px;
}

.material .el-submenu__title:hover {
    background: #b76c12;
}

.material .subtitle .el-submenu.is-opened>.el-submenu__title {
    background: rgba(183, 108, 18, 0.3);
}

.material .el-submenu__title i {
    color: #ffffff;
}

.material .el-menu-item,
.material .el-submenu__title {
    height: 48px;
    line-height: 48px;
}

.material .subtitle {
    border: 1px solid #f2f2f2;
}

.material .subtitle .el-submenu__title i {
    color: #333333;
}

.material .subtitle .el-submenu__title {
    padding-left: 30px !important;
    background: #ffffff;
    color: #333333;
    margin-top: 0;
    height: 36px;
    line-height: 36px;
}

.material .el-menu-item.is-active {
    color: #b76c12;
}

.material .subtitle .el-submenu__title:hover {
    color: #b76c12;
}

.material .el-submenu .el-menu-item {
    padding-left: 50px !important;
    height: 36px;
    line-height: 36px;
}

.material .el-menu-item:focus,
.material .el-menu-item:hover {
    background-color: rgba(183, 108, 18, 0.2);
    color: #b76c12;
}

.material .el-pagination.is-background .btn-next,
.material .el-pagination.is-background .btn-prev,
.material .el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.material .el-pagination {
    font-weight: 500;
}

.material .el-submenu__title * {
    margin-left: 10px;
}

.material .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #b76c12;
    color: #FFF;
}

/*.material .el-pagination.is-background .el-pager li.number:hover{
        color: #b76c12;
    }*/
.material .el-pagination.is-background .el-pager li:hover {
    color: #b76c12;
}
</style>
